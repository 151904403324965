import React from "react";
import { Helmet } from "react-helmet";

const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const wrapPageElement = ({ element, props }) => {
  window._paq = window._paq || [];
  window.dataLayer = window.dataLayer || [];

  window._paq.push([
    "setCustomDimension",
    1,
    props?.pageContext?.network?.global?.name,
  ]);

  window._paq.push([
    "setCustomDimension",
    2,
    props?.pageContext?.vertical?.global?.name,
  ]);

  window._paq.push([
    "setCustomDimension",
    3,
    props?.pageContext?.site?.properties?.country?.data?.value || "ROW",
  ]);

  window._paq.push([
    "setCustomDimension",
    4,
    props?.pageContext?.site?.language?.languageTag,
  ]);

  window._paq.push([
    "setCustomDimension",
    5,
    props?.pageContext?.pageType
      ? capitalize(props?.pageContext?.pageType)
      : "Frontpage",
  ]);

  let section, subSection, filterSection;
  switch (props?.pageContext?.pageType) {
    case "provider":
      section = props?.pageContext?.provider?.name;
      if (props?.pageContext?.extendedReviewSection) {
        subSection = props?.pageContext?.extendedReviewSection?.group;
      }
      break;
    case "taxonomyParent":
      section = props?.pageContext?.taxonomy?.global?.name;
      break;
    case "taxonomyChild":
      section = props?.pageContext?.taxonomy?.global?.parentName; //Parent taxonomy should show here
      subSection = props?.pageContext?.taxonomy?.global?.name;
      break;
    case "page":
      section = props?.pageContext?.page?.global?.title;
      break;
    case "taxonomyNews":
      section = props?.pageContext?.taxonomy?.global?.name;
      break;
    case "post":
      section = props?.pageContext?.post?.global?.taxonomyName;
      subSection = props?.pageContext?.post?.global?.title;
      break;
    case "taxonomyFilter":
      section = props?.pageContext?.filterTaxonomy?.global?.baseParentName;
      subSection = props?.pageContext?.filterTaxonomy?.global?.baseName;
      filterSection = props?.pageContext?.filterTaxonomy?.global?.filterName;
      break;
    case "index":
      section = "Frontpage";
      break;
    default:
      break;
  }

  if (section) window._paq.push(["setCustomDimension", 6, section]);
  if (subSection) window._paq.push(["setCustomDimension", 7, subSection]);
  if (filterSection) window._paq.push(["setCustomDimension", 8, filterSection]);

  window.dataLayer.push({
    event: "pageview",
    network: props?.pageContext?.network?.global?.name,
    vertical: props?.pageContext?.vertical?.global?.name,
    country:
      props?.pageContext?.site?.properties?.country?.data?.value || "ROW",
    language: props?.pageContext?.site?.language?.languageTag,
    pagetype: props?.pageContext?.pageType
      ? capitalize(props?.pageContext?.pageType)
      : "Frontpage",
    ...(section && { section: section }),
    ...(subSection && { subsection: subSection }),
    ...(filterSection && { filtersection: filterSection }),
  });

  return (
    <React.Fragment>
      <Helmet>
        <script type="text/javascript">
          {`(function(window, document, dataLayerName, id) {
window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
tags.async=!0,tags.src="//xstat.org/containers/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
})(window, document, 'dataLayer', 'd70717a8-4f42-498a-962e-6739693b0b2a');`}
        </script>
        <noscript>
          {`<iframe src="//xstat.org/containers/d70717a8-4f42-498a-962e-6739693b0b2a/noscript.html" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      </Helmet>
      {element}
    </React.Fragment>
  );
};
