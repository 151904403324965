// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-allan-core-src-templates-casinorank-index-js": () => import("./../../../../gatsby-theme-allan-core/src/templates/casinorank/index.js" /* webpackChunkName: "component---gatsby-theme-allan-core-src-templates-casinorank-index-js" */),
  "component---gatsby-theme-allan-core-src-templates-slotsrank-404-js": () => import("./../../../../gatsby-theme-allan-core/src/templates/slotsrank/404.js" /* webpackChunkName: "component---gatsby-theme-allan-core-src-templates-slotsrank-404-js" */),
  "component---gatsby-theme-allan-core-src-templates-slotsrank-news-js": () => import("./../../../../gatsby-theme-allan-core/src/templates/slotsrank/news.js" /* webpackChunkName: "component---gatsby-theme-allan-core-src-templates-slotsrank-news-js" */),
  "component---gatsby-theme-allan-core-src-templates-slotsrank-page-js": () => import("./../../../../gatsby-theme-allan-core/src/templates/slotsrank/page.js" /* webpackChunkName: "component---gatsby-theme-allan-core-src-templates-slotsrank-page-js" */),
  "component---gatsby-theme-allan-core-src-templates-slotsrank-post-js": () => import("./../../../../gatsby-theme-allan-core/src/templates/slotsrank/post.js" /* webpackChunkName: "component---gatsby-theme-allan-core-src-templates-slotsrank-post-js" */),
  "component---gatsby-theme-allan-core-src-templates-slotsrank-provider-js": () => import("./../../../../gatsby-theme-allan-core/src/templates/slotsrank/provider.js" /* webpackChunkName: "component---gatsby-theme-allan-core-src-templates-slotsrank-provider-js" */),
  "component---gatsby-theme-allan-core-src-templates-slotsrank-taxonomy-js": () => import("./../../../../gatsby-theme-allan-core/src/templates/slotsrank/taxonomy.js" /* webpackChunkName: "component---gatsby-theme-allan-core-src-templates-slotsrank-taxonomy-js" */)
}

